.payment-guide-container {
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.payment-guide-container .left {
  max-width: 100%;

  @media (min-width: 576px) {
    max-width: 300px;
  }
}
